<template>
  <VContainer
    fluid
    class="pa-0 text-center"
  >
    <VRow
      justify="center"
    >
      <VCol cols="auto">
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-track-management-close"
          class="mb-2 v-btn--aside"
          @click="handleClickClose"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-times
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Закрыть
        </div>
      </VCol>
      <VCol
        v-if="showAddContentFormButton"
        cols="auto"
      >
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-track-management-add-content"
          class="mb-2 v-btn--aside"
          @click="handleClickOpenAddContentForm"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-plus
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Создать
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
export default {
  name: 'AppTrackManagementAsideRight',
  computed: {
    showAddContentFormButton() {
      return this.$route.hash === '#content';
    },
  },
  methods: {
    handleClickOpenAddContentForm() {
      this.$root.$emit('click:open-content-form');
    },
    handleClickClose() {
      this.$root.$emit('click:close-management');
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
