<template>
  <VContainer
    fluid
    class="pa-0 text-center"
  >
    <VRow
      justify="center"
    >
      <VCol cols="auto">
        <TTBtn
          fab
          depressed
          color="white"
          elevation="1"
          large
          data-test="aside-right-tracks-edit"
          class="mb-2 v-btn--aside"
          @click="handleClickOpen"
        >
          <VIcon
            color="tt-black"
            size="19"
          >
            fal fa-pen
          </VIcon>
        </TTBtn>
        <div class="tt-text-caption tt-black--text text--lighten-2">
          Редактировать
        </div>
      </VCol>
    </VRow>
  </VContainer>
</template>
<script>
export default {
  name: 'AppTrackAsideRight',
  methods: {
    handleClickOpen() {
      this.$root.$emit('click:open-track-edit');
    },
  },
};
</script>

<style scoped>
/* stylelint-ignore no-empty-source */
</style>
