<template>
  <VContainer fluid>
    <VRow>
      <VCol>
        <Transition
          name="fade"
          mode="out-in"
        >
          <h1
            :key="currentPageTitle"
            class="tt-text-headline-1"
            data-test="tracks-title"
          >
            {{ currentPageTitle }}
          </h1>
        </Transition>
      </VCol>
    </VRow>
    <VRow>
      <VCol cols="6">
        <TTTextField
          v-model="searchValue"
          clear-icon="fas fa-times-circle"
          append-icon="fal fa-search"
          large
          label="Поиск"
          placeholder="Поиск по названию"
          clearable
          data-test="tracks-search"
        />
      </VCol>
    </VRow>
    <VRow v-show="(showAll || showOnlyDraft) && draftSearchCount>0">
      <VCol>
        <VDataIterator
          :items="draftCardsInit"
          :search="searchValue"
          :items-per-page="draftItemCountsToShow"
          :custom-filter="customFilter"
          hide-default-footer
          @current-items="handleCurrentDraftItems"
        >
          <template
            v-if="showAll"
            #header
          >
            <VRow
              align="center"
              dense
            >
              <VCol cols="auto">
                <span
                  class="tt-text-title-2"
                  data-test="tracks-subtitle-draft"
                >
                  Черновики
                </span>
              </VCol>
              <VCol
                v-if="showLinkAllDrafts"
                cols="auto"
              >
                <TTBtn
                  text
                  class="tt-text-body-2"
                  height="auto"
                  data-test-label="all-draft-button"
                  @click="handleClickShowAllDraft"
                >
                  Все {{ draftCardsInit.length }}
                  <VIcon>
                    fal fa-angle-right
                  </VIcon>
                </TTBtn>
              </VCol>
            </VRow>
          </template>
          <template #default="props">
            <VRow>
              <VCol
                v-for="(item,index) in props.items"
                :key="item.title"
                cols="3"
              >
                <TrackCard
                  link
                  data-test="tracks-draft-cards"
                  :image-src="item.imageSrc"
                  :title="item.title"
                  :to="{name : Names.R_TRACK,params : {id : index}}"
                  :text="item.text"
                  :menu-items="generateTrackCardMenuList(index,'draft',item.title)"
                />
              </VCol>
            </VRow>
          </template>
          <template #no-results>
            <NoResult data-test="no-result-draft" />
          </template>
        </VDataIterator>
      </VCol>
    </VRow>
    <VRow v-show="(showAll || showOnlyPublished) && (publishedSearchCount>0)">
      <VCol>
        <VDataIterator
          :items="cardsInit"
          :search="searchValue"
          hide-default-footer
          :custom-filter="customFilter"
          :items-per-page="-1"
          @current-items="handleCurrentPublishedItems"
        >
          <template
            v-if="showAll"
            #header
          >
            <VRow
              align="center"
              dense
            >
              <VCol cols="auto">
                <span
                  class="tt-text-title-2"
                  data-test="tracks-subtitle"
                >
                  Опубликованные
                </span>
              </VCol>
            </VRow>
          </template>
          <template
            #default="props"
          >
            <VRow>
              <VCol
                v-for="(item,index) in props.items"
                :key="item.title"
                cols="3"
              >
                <TrackCard
                  link
                  data-test="tracks-cards"
                  :to="{name : Names.R_TRACK,params : {id : index}}"
                  :image-src="item.imageSrc"
                  :title="item.title"
                  :text="item.text"
                  :menu-items="generateTrackCardMenuList(index,'published',item.title)"
                />
              </VCol>
            </VRow>
          </template>
          <template #no-results>
            <NoResult data-test="no-result-published" />
          </template>
        </VDataIterator>
      </VCol>
    </VRow>
    <VRow v-if="noResult">
      <VCol>
        <NoResult data-test="no-result" />
      </VCol>
    </VRow>
    <VRow v-if="isEmpty">
      <VCol>
        <EmptySection
          data-test="empty-section"
          title="Сначала создайте трек адаптации"
          text="Здесь появятся ваши материалы, которые можно будет наполнить контентом."
        />
      </VCol>
    </VRow>
  </VContainer>
</template>

<script>

import TrackCard from '@/components/cards/TrackCard.vue';
import debounce from 'lodash/debounce';
import NoResult from '@/components/shared/NoResult.vue';
import EmptySection from '@/components/shared/EmptySection.vue';

export default {
  name: 'Tracks',
  components: {
    EmptySection,
    NoResult,
    TrackCard,
  },
  inject: ['Names'],
  props: {
    type: {
      type: [String],
      default: 'all',
      validator: (val) => ['all', 'draft', 'published'].includes(val),
    },
  },
  data() {
    return {
      searchValue: '',
      handleTextSearch: Function,
      draftCardsShowCount: 4,
      draftCardsInit: [...Array(5)]
        .map((_, index) => (
          {
            id: index,
            title: `Card Title ${index}`,
            imageSrc: '',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam, aliquid eligendi esse ex fugit illo impedit ipsa maiores nam nisi nostrum perspiciatis, reprehenderit, sed voluptate. Corporis illo ipsa reiciendis.\n',
          })),
      cardsInit: [...Array(5)]
        .map((_, index) => (
          {
            id: index,
            title: `Card Title ${index}`,
            imageSrc: 'https://loremflickr.com/240/124',
            // eslint-disable-next-line max-len,vue/max-len
            text: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Accusamus aliquam, aliquid eligendi esse ex fugit illo impedit ipsa maiores nam nisi nostrum perspiciatis, reprehenderit, sed voluptate. Corporis illo ipsa reiciendis.\n',
          })),
      publishedSearchCount: -1,
      draftSearchCount: -1,
    };
  },
  computed: {
    currentPageTitle() {
      switch (this.type) {
        case 'draft':
          return 'Черновики';
        case 'published':
          return 'Опубликованные';
        default:
        case 'all':
          return 'Треки';
      }
    },
    showAll() {
      return this.type === 'all';
    },
    showOnlyDraft() {
      return this.type === 'draft';
    },
    showOnlyPublished() {
      return this.type === 'published';
    },
    draftItemCountsToShow() {
      return this.showOnlyDraft ? -1 : 4;
    },
    noResult() {
      return this.publishedSearchCount === 0
        && this.draftSearchCount === 0 && (this.draftCardsInit.length > 0 || this.cardsInit.length > 0);
    },
    isEmpty() {
      return this.draftCardsInit.length === 0 && this.cardsInit.length === 0;
    },
    showLinkAllDrafts() {
      return this.draftItemCountsToShow !== -1 && this.draftCardsInit.length > 4;
    },
  },
  mounted() {
    this.handleTextSearch = debounce(this.search, 500);
    this.$root.$on('click:open-track-create', this.showCreateForm);
    // TODO пример нового запроса через OpenAPI
    console.log(this.$di.api.auth.authentication.logout());
  },
  beforeDestroy() {
    this.$root.$off('click:open-track-create', this.showCreateForm);
  },
  methods: {
    generateTrackCardMenuList(id, type, title) {
      const confirmationDeleteAction = async () => {
        this.deleteTrack(id, type);
      };
      const editAction = async () => {
        this.$router.push({ name: this.Names.R_TRACK_MANAGEMENT, params: { id } });
      };
      const menuDeleteAction = () => {
        this.$root.$emit('showConfirmation', {
          title: 'Удалить трек?',
          description: `Трек «${title}» включая содержание, назначения будут удалены из системы.`,
          confirmAction: confirmationDeleteAction,
          confirmButtonColor: 'tt-danger',
          confirmButtonText: 'Удалить',
        });
      };
      return [
        {
          icon: 'fal fa-pen',
          color: '',
          text: 'Редактировать',
          dataTest: 'track-button-open-update-form',
          action: editAction,
        }, {
          icon: 'fal fa-trash-alt',
          color: 'tt-light-red',
          text: 'Удалить',
          dataTest: 'track-button-open-delete-form',
          action: menuDeleteAction,
        },
      ];
    },
    deleteTrack(id, type) {
      if (type === 'published') {
        const removeItemIndex = this.cardsInit.find((item) => item.id === id);
        this.cardsInit.splice(removeItemIndex, 1);
      } else {
        const removeItemIndex = this.draftCardsInit.find((item) => item.id === id);
        this.draftCardsInit.splice(removeItemIndex, 1);
      }
    },
    handleClickShowAllDraft() {
      this.$router.push({
        name: this.Names.R_TRACKS,
        query: { type: 'draft' },
      });
    },
    search(value) {
      this.searchValue = value;
    },
    customFilter(items, search) {
      if (!search) return items;
      return items.filter((item) => item.title.toUpperCase()
        .includes(search.toUpperCase()));
    },
    handleCurrentPublishedItems(items) {
      this.publishedSearchCount = items.length;
    },
    handleCurrentDraftItems(items) {
      this.draftSearchCount = items.length;
    },
    showCreateForm() {
      this.$router.push({ name: this.Names.R_TRACK_CREATE });
    },
  },
};
</script>
